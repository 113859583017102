define("discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/dates", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/runloop", "@ember/service", "@ember/template", "discourse/lib/local-dates", "discourse/lib/text", "discourse-common/helpers/d-icon", "discourse/plugins/discourse-calendar/discourse/lib/guess-best-date-format", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _runloop, _service, _template, _localDates, _text, _dIcon, _guessBestDateFormat, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoursePostEventDates extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "htmlDates", [_tracking.tracked], function () {
      return "";
    }))();
    #htmlDates = (() => (dt7948.i(this, "htmlDates"), void 0))();
    get startsAt() {
      return moment(this.args.event.startsAt).tz(this.timezone);
    }
    get endsAt() {
      return this.args.event.endsAt && moment(this.args.event.endsAt).tz(this.timezone);
    }
    get timezone() {
      return this.args.event.timezone || "UTC";
    }
    get format() {
      return (0, _guessBestDateFormat.default)(this.startsAt, this.endsAt);
    }
    get datesBBCode() {
      const dates = [];
      dates.push(`[date=${this.startsAt.format("YYYY-MM-DD")} time=${this.startsAt.format("HH:mm")} format=${this.format} timezone=${this.timezone}]`);
      if (this.endsAt) {
        dates.push(`[date=${this.endsAt.format("YYYY-MM-DD")} time=${this.endsAt.format("HH:mm")} format=${this.format} timezone=${this.timezone}]`);
      }
      return dates;
    }
    async computeDates(element) {
      if (this.siteSettings.discourse_local_dates_enabled) {
        const result = await (0, _text.cook)(this.datesBBCode.join("<span> → </span>"));
        this.htmlDates = (0, _template.htmlSafe)(result.toString());
        (0, _runloop.schedule)("afterRender", () => {
          (0, _localDates.applyLocalDates)(element.querySelectorAll(`[data-post-id="${this.args.event.id}"] .discourse-local-date`), this.siteSettings);
        });
      } else {
        let dates = `${this.startsAt.format(this.format)}`;
        if (this.endsAt) {
          dates += ` → ${moment(this.endsAt).format(this.format)}`;
        }
        this.htmlDates = (0, _template.htmlSafe)(dates);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "computeDates", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="event__section event-dates" {{didInsert this.computeDates}}>
          {{icon "clock"}}{{this.htmlDates}}</section>
      
    */
    {
      "id": "M8tS1M4H",
      "block": "[[[1,\"\\n    \"],[11,\"section\"],[24,0,\"event__section event-dates\"],[4,[32,0],[[30,0,[\"computeDates\"]]],null],[12],[1,\"\\n      \"],[1,[28,[32,1],[\"clock\"],null]],[1,[30,0,[\"htmlDates\"]]],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/dates.js",
      "scope": () => [_didInsert.default, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DiscoursePostEventDates;
});